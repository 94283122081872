import { Button } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useAppStateContext } from '../../AppStateProvider/AppStateProvider';
import { getFormattedDate } from '../../../utils';
import { FF_BITRATE_REPORT_ENABLED } from '../../../constants';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const GeneratePDFButton = () => {
  const { finalResultsMinified } = useAppStateContext();

  const DownloadPDF = () => {
    const docDefinition = CreateDocDefinition(finalResultsMinified());
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download(`test-results${getFormattedDate()}.pdf`);
  };

  return (
    <div>
      <Button variant="contained" color="primary" startIcon={<CloudDownload />} onClick={DownloadPDF}>
        Download test results
      </Button>
    </div>
  );
};

const CreateDocDefinition = (data: any) => {
  const preflightTestReport = [
    `Jitter Avg: ${data.preflightTestReport.jitterAvg}`,
    `Jitter Max: ${data.preflightTestReport.itterMax}`,
    `RTT Avg: ${data.preflightTestReport.rttAvg}`,
    `RTT Max: ${data.preflightTestReport.rttMax}`,
    `Packet Loss Avg: ${data.preflightTestReport.packetLossAvg}`,
    `Packet Loss Max: ${data.preflightTestReport.packetLossMax}`,
  ];

  if (FF_BITRATE_REPORT_ENABLED) {
    preflightTestReport.push(
      `Bitrate Avg: ${data.preflightTestReport.bitrateAvg}`,
      `Bitrate Max: ${data.preflightTestReport.bitrateMax}`
    );
  }

  return {
    content: [
      { text: 'Test Result of ' + data.email, style: 'header' },
      { text: '\n' },
      {
        table: {
          widths: ['auto', '*'],
          body: [
            ['Date:', data.date],
            ['ID:', data.id],
            ['Email:', data.email],
            ['Browser Info:', `${data.browserInfo.name} ${data.browserInfo.version}`],
            ['OS:', `${data.operatingSystem.name} ${data.operatingSystem.version}`],
            ['Input Device:', data.inputDevice],
            [
              'Connectivity:',
              {
                ul: [`Signaling Region: ${data.connectivity.signalingRegion}`, `TURN: ${data.connectivity.TURN}`],
              },
            ],
            [
              'Twilio Services:',
              {
                ul: [Object.entries(data.twilioServices).map(([key, value]) => `${key}: ${value}`)],
              },
            ],
            [
              'Ciptex Services:',
              {
                ul: [
                  `Booking API: ${data.ciptexServices.bookingApiStatus}`,
                  `Conference API: ${data.ciptexServices.conferenceApiStatus}`,
                  `Phone API: ${data.ciptexServices.phoneNumberApiStatus}`,
                ],
              },
            ],
            [
              'Preflight Test Report:',
              {
                ul: preflightTestReport,
              },
            ],
          ],
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'left',
      },
    },
  };
};

export default GeneratePDFButton;
