import React, { useCallback } from 'react';
import { ACTIONTYPE, CiptexStatus } from '../AppStateProvider';
import axios from 'axios';

export default function useCiptexStatus(dispatch: React.Dispatch<ACTIONTYPE>) {
  const getCiptexStatus = useCallback(() => {
    return Promise.allSettled([
      axios.get('https://notified-booking-api.gll.ciptex.io/.well-known/health-check'),
      axios.get('https://conference-control-api.gll.ciptex.com/.well-known/health-check'),
      axios.get('https://phonenumber-api.gll.ciptex.com/.well-known/health-check'),
    ])
      .then((responses) => {
        const [bookingApiStatus, conferenceApiStatus, phoneNumberApiStatus] = responses;

        const statusObj: CiptexStatus = {
          bookingApiStatus: bookingApiStatus.status === 'fulfilled' ? 'operational' : '',
          conferenceApiStatus: conferenceApiStatus.status === 'fulfilled' ? 'operational' : '',
          phoneNumberApiStatus: phoneNumberApiStatus.status === 'fulfilled' ? 'operational' : '',
        };

        dispatch({ type: 'set-ciptex-status', statusObj });

        const failedRequests = responses.filter((r) => r.status === 'rejected');

        if (failedRequests.length) {
          dispatch({ type: 'set-ciptex-status-error', error: (failedRequests[0] as any).reason });
        }
      })
      .catch((error) => {
        dispatch({ type: 'set-ciptex-status-error', error });
      });
  }, [dispatch]);

  return { getCiptexStatus } as const;
}
